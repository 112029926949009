<template>
    <div class="modal-wrap">
        <div class="pop"></div>
        <div class="pop-modal fixed">
            <div class="modal-title">{{ modalInfo.title }}</div>
            <div class="input_wrap">
                <div class="input_nav flex" v-for="(item, index) in modalInfo.inputList" :key="index">
                    <div class="label">{{ item.label }}</div>
                    <div class="val">
                        <Select v-if="item.inputType == 'select'" v-model="item.inputVal" style="width:200px">
                            <Option v-for="i in item.datalist" :value="i.id" :key="i.id">{{ i.name }}</Option>
                        </Select>
                        <Input v-else v-model="item.inputVal" :type="item.inputType"
                            :placeholder="'请输入' + item.label" />
                    </div>
                </div>
            </div>
            <div class="give">
                <div class="cancel flex-c" @click="closeModal">取 消</div>
                <div class="require flex-c" @click="giveVal">确 定</div>
            </div>
            <div class="close-icon" @click="closeModal">
                <Icon type="md-close" size="24" />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "index",
    props: {
        modalInfo: {
            type: Object
        }
    },
    data() {
        return {
            contract_name: ''
        }
    },
    methods: {
        giveVal() {
            let arr = this.modalInfo.inputList || []
            let giveVal = []
            arr.forEach(item => {
                giveVal.push(item.inputVal)
            })
            this.$emit('giveVal', giveVal)
        },
        closeModal() {
            this.$emit('closeModal')
        }
    }
}
</script>

<style scoped lang="scss">
.input_wrap {
    padding: 0px 45px;
    border-bottom: 1px solid #e8eaec;
}

.close-icon {
    position: absolute;
    top: 15px;
    right: 15px;
    font-size: 10px;
    cursor: pointer;
}

.queren {
    display: inline-block;
    margin-bottom: 0;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;
    background-image: none;
    border: 1px solid transparent;
    white-space: nowrap;
    // line-height: 1.5;
    -webkit-user-select: none;
    user-select: none;
    height: 32px;
    line-height: 32px;
    padding: 0 15px;
    font-size: 14px;
    border-radius: 4px;
    transition: color .2s linear, background-color .2s linear, border .2s linear, box-shadow .2s linear;
    color: #fff;
    background-color: #2d8cf0;
    border-color: #2d8cf0;
}

.modal-wrap {
    .pop-modal {
        width: 600px;
        background: #FFFFFF;
        box-shadow: 0px 2px 9px 0px rgba(72, 119, 232, 0.39);
        border-radius: 6px;
        padding: 16px 0px;
        box-sizing: border-box;
        z-index: 999;

        .modal-title {
            font-size: 16px;
            color: #17233d;
            border-bottom: 1px solid #e8eaec;
            margin-bottom: 25px;
            padding-bottom: 12px;
            padding-left: 16px;
        }

        .input_nav {
            margin-bottom: 25px;

            .label {
                font-size: 14px;
                font-weight: 400;
                color: #515a6e;
                width: 80px;
                text-align: center;
            }

            .val {
                flex: 1;
            }
        }

        .give {
            display: flex;
            justify-content: right;
            margin-top: 12px;
            padding-right: 24px;

        }

        .require {
            width: 60px;
            height: 32px;

            background: #2d8cf0;
            border: 1px solid #2d8cf0;
            border-radius: 4px;
            font-size: 14px;
            font-weight: 400;
            color: #FFFFFF;
            cursor: pointer;

            &:hover {
                opacity: 0.6;
            }
        }

        .cancel {
            width: 60px;
            height: 34px;
            color: #515a6e;
            border-radius: 6px;
            font-size: 14px;
            font-weight: 400;
            cursor: pointer;
            margin-right: 20px;

            &:hover {
                color: #4877E8;
            }
        }
    }
}
</style>
